<template lang="pug">
div
  AlgoliaWrapper(
    v-if='searchContext'
    :key='data._uid'
    :parent-id='data._uid'
    :search-context='searchContext'
    :data-storyblok-id='data._uid'
  )
    template(v-if='data.sections')
      template(v-for='widget in data.sections')
        slot(:data='widget')
</template>

<script setup lang="ts">
const route = useRoute()
const { $algolia, $device } = useNuxtApp()
interface items {
  items: [
    {
      name: string
      value: string
    }
  ]
}

interface searchData extends Widgets {
  sections: Widget[]
  mobileResultsPerPage: string
  tabletResultsPerPage: string
  desktopResultsPerPage: string
  filterByAllOf?: string[]
  filterByAnyOf?: string[]
  filterByAuthor?: string[]
  filterByProduct?: items
  filterByCategory?: items
  filterByBrand?: items
  onlyShowSaleProducts?: boolean
  excludeCurrentUrl: boolean // ??
}
const { data } = defineProps<{
  data: searchData
}>()

let searchContext: any = undefined
if (data.component === 'search-section-content') searchContext = $algolia.addDynamicContentContext(data._uid)
else searchContext = $algolia.addDynamicProductContext(data._uid)

provide('displayType', data.component === 'search-section-content' ? 'CONTENT' : 'PRODUCT')

const { $timing } = useNuxtApp()

const fetchAlgoliaData = async () => {
  searchContext.setResultsPerPage(_getResultsPerPage())
  searchContext.clearFilters(null)

  switch (data.component) {
    case 'search-section-content':
      if (data.excludeCurrentUrl) searchContext.addFilters('NOT url', route.path, ':', 'AND')
      // Should only add filters if they exist otherwise our query builder will try to join empty strings together
      if (data.filterByAllOf && data.filterByAllOf.length > 0)
        searchContext.addFilters('_tags', data.filterByAllOf, ':', 'AND')

      if (data.filterByAnyOf && data.filterByAnyOf.length > 0)
        searchContext.addFilters('_tags', data.filterByAnyOf, ':', 'OR')

      if (data.filterByAuthor && data.filterByAuthor.length > 0) {
        searchContext.addFilters('contributors._uid', data.filterByAuthor, ':', 'OR')
      }
      break
    case 'search-section-product':
      searchContext.addFilters('isAccessory', false)
      // Products trump cats/brands
      if (data.filterByProduct && data.filterByProduct.items?.length > 0) {
        // Products should always return 30 so we get the full data set
        searchContext.setResultsPerPage(30)
        // Add filters and create the sort order array
        const sortOrder = data.filterByProduct.items.map((item: any) => {
          if (searchContext) searchContext.addFilters('productLineSlug', item.value)
          return item.value
        })

        searchContext.addSortCallback((a: any, b: any) => {
          return sortOrder.indexOf(a.productLineSlug) - sortOrder.indexOf(b.productLineSlug)
        })
      } else {
        // Only filter by Category or Brands if they did not provide Product Names
        if (data.filterByCategory?.items?.length) {
          data.filterByCategory.items.forEach((item) => {
            const lastLevel = item.value.split('|').length - 1
            if (searchContext) searchContext.addFilters(`categories.lvl${lastLevel}.slug`, item.value)
          })
        }

        if (data.filterByBrand?.items?.length) {
          data.filterByBrand.items.forEach((item) => {
            if (searchContext) searchContext.addFilters('brandSlug', item.value)
          })
        }

        // On Sale Filter
        if (data.onlyShowSaleProducts) searchContext.addFilters('hasSpecial', 'true')
      }
      break
  }

  const timingKey = `storyblok-algolia-${data._uid}`
  $timing.start(timingKey)
  await searchContext.search()
  $timing.end(timingKey)
}

function _getResultsPerPage() {
  // setResultsPerPage
  const mobile = data.mobileResultsPerPage ? data.mobileResultsPerPage : 30
  const tablet = data.tabletResultsPerPage ? data.tabletResultsPerPage : mobile
  const desktop = data.desktopResultsPerPage ? data.desktopResultsPerPage : tablet

  if ($device.value.isMobile) return mobile
  else if ($device.value.isDesktop) return desktop
  else return tablet
}

await callOnce(data._uid, async () => {
  await fetchAlgoliaData()
})
</script>
